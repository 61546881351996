.frame-picker{
  border-top: thin solid #efefef;
  padding-top: 10px;
  background: #fdfdfd;
  min-height: 170px;
}

.frame-picker__list{
  display: flex;
  list-style:none;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  margin-left: 5px;
}

.frame-picker__list-item{
  padding: 10px;
  border: 1px solid #efefef;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 2px #dedede;
  margin: 10px;
  background:white;
  text-align: center;
  &.active {
    box-shadow: 1px 1px 6px 2px #f443362e;
  }
}