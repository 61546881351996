.editorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  padding: 20px 0;
  margin-top: 30px;
}
.ant-modal-footer {
  border: 0px;
  padding: 0px;
}
.ant-custom-btn,
.ant-custom-btn:hover,
.ant-custom-btn:focus {
  background-color: #4d5463;
  border-color: #4d5463;
  color: #fff;
}

.ant-custom-btn:hover,
.ant-custom-btn:focus {
  opacity: 0.8;
}

#canvasContainer {
  width: 80%;
}

.textEditFunctions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  /* justify-content: center; */
}
/* .text-area{
  width: 25vmax;
  height: 25vmax;  
} */
.text-area-safari {
  width: 100%;
  height: 100%;
  align-self: flex-start;
}
.text-area {
  width: 100%;
  height: 100%;
}
.textAreaContainer {
  /* height: 30vmax; */
  max-width: 600px;
  width: 100vw;
  height: auto; /*60vh;*/
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* border: 1px solid #333; */
}
.textAreaContainerFixed {
  /* height: 30vmax; */
  max-width: 100%;
  width: 100vw;
  height: 60vh;
  /* padding: 10px; */
  display: flex;
  /* height: 25vmax; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #333; */
}

.textAreaContainer textarea {
  /* border: 0; */
  resize: none;
  /* background-color: #666; */
  outline: none;
  color: #666;
  min-height: 40px;
  padding: 5px;
  &:focus{
    border: 1px solid black !important;
  }
}
.ant-modal-body {
  /* overflow: hidden !important; */
}
.ant-modal-wrap-text-editor {
  /* overflow: hidden; */
}

.actions{
  position: relative;
  background-color: white;
  padding: 10px;
  border: 1px solid #f2f2f2;
  /*margin: 0px 20px;*/
  margin-top: 10px;
}

.actions__help-title{
  position: absolute;
  top: -10px;
  left: 23px;
  background: white;
  font-size: 11px;
  padding: 0px 10px;
  border: 1px solid #e4e4e4;
  border: 0px;
}

.optionGroup {
  /* border: 1px solid #333; */
  display: flex;
  flex-direction: row;
  padding: 3px;
  gap: 12px;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.coverArrowContainer{
  position: absolute;
  bottom: -34px;
  left: -50px;
}

.coverArrowContainer > .icon {
  width: 50px;
  height: 50px;
}
.coverArrowContainer > .icon > svg{
  width: 50px;
  height: 50px;
}

.options {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 20px;
}
.word-row {
  font-weight: bold;
  padding: 5px;
  width: 25px;
  padding-right: 20px;
  align-self: flex-end;
}
