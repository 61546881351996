@import "../../styles/abstracts/variables";
$tt-basket-badge-dimensions: 18px;
$tt-basket-badge-offset: 0px;

.shop-button {
  position: relative;
  margin-right: $tt-spacing-unit-default;
  cursor: pointer;
  .btn {
    margin-right: 0;
  }
  &:hover{
    .icon{
      cursor: pointer;
    }
    
    svg > path{
      //fill: #ff5a5f;
      filter: opacity(0.7);
    }
  }
}
