@import "../../styles/abstracts/variables";
.announcement-preview{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-height: 100%;
  width:100%;
  height: 100%;
}
.announcement-preview__render {
  flex: 1 1 0%;
  min-height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .page__number {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    color: #fff;
    background: rgba(#000, 0.8);
    padding: 1rem;
  }
}

.announcement-preview__navigation {
  margin-top: $tt-spacing-unit-default; // auto
  min-height: 25%;
  height: 100%;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.preview-explainer-text{
  font-size: 12px;
  margin-top: 10px;
  opacity: 1;
  font-weight: 400;
}

.announcement__additional-info{
  text-align: center;
  margin-top: 10px;
}