@import "../../styles/abstracts/variables";

.product-preview {
  padding: $tt-spacing-unit-default;
  border-radius: $tt-spacing-unit-small;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 6px rgba(#000, 0.1);
  transition: all .2s;

  &:active {
    box-shadow: 0 0 10px rgba(#000, 0.2);
  }
}

.product-preview--highlighted {
  background-color: $tt-color-primary;

  .product-preview__dimensions-indicator {
    background: rgba($tt-color-text-light, 0.5);
  }

  .product-preview__name {
    color: $tt-color-text-light;
  }
}

.product-preview__dimensions-indicator {
  background: rgba($tt-color-text-dark, 0.2);
  margin-right: $tt-spacing-unit-default;
}

.product-preview__info {
  flex: 1;
}

.product-preview__name {
  color: $tt-color-primary;
  font-weight: $tt-font-weight-bold;
}

.product-preview__pricing {
  color: rgba($tt-color-text-dark, 0.6);
  font-size: $tt-font-size-small;
}
