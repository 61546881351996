.tile-preview-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  overflow: scroll;
}

.tile-preview-container {
  padding-top: 10%;
  justify-content: center;
  display: flex;
  flex: 1;
}

.header-wrapper {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}
