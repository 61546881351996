.photo-prints-text-band {
    text-align: center;
    pointer-events: none;
    position: absolute;
    bottom: -11.1%;
    left: 0;
    right: 0;
    height: 11.1%;
    background-color: #ffffffc7;
    margin-bottom: -16px;
    border-radius: 2px;
  }
  
  .photo-prints-text-band__text {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  