
.edit-print-modal-container{
  margin-top: -15px;
  .ant-switch-checked {
    background-color: #4CAF50 !important;
  }
}

.edit-print-modal-main-heading{
  font-size: 18px;
}

.edit-print-modal-heading{
  font-size: 16px;
  text-align: center;
}

.edit-print-modal-sub-heading{
  font-size: 14px;
  margin: 10px 0px;
  display: inline-block;
}

.fixed-preview{
  margin-top:15px;
}

.edit-print-options{
  border: 1px solid #4B5565;
  padding: 5px;
  border-radius: 4px;
  background: white;
  display: flex;
  max-width: 430px;
  //margin: 0 auto;
}

.edit-print-modal__slider{
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  background: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-height:55px;
  padding: 5px 0px 0px 5px;
  .swatch-container{
    border: 2px solid transparent;
    display: inline-block;
    margin-right: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 3px;
    &.retro-shape{
       &.active{
        border-radius: 0;
       } 
      .swatch{
        width: calc(50px * 0.83);
        height: 50px;
        border-radius: 0;
        border: 1px solid #e0e0e0;
      }  
    }
    .swatch{
      //border-radius: 50%;
      height: 50px;
      width: 50px;
      border: 1px solid #e0e0e0;
    }

    &.active{
      border: 2px solid #ff5a5f;
      //border-radius: 50%;
    }
  }
}

.edit-print-text{
  textarea{
    width: 100%;
    font-size: 12px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}

.edit-print-text-tools{
  //display: flex;
}

.edit-section{
  background: #cccccc38;
  //padding: 15px;
  margin: 20px 0px;
  border-radius: 10px;
  //border: 1px solid #e4e4e4;
  padding: 5px 15px 20px 15px;
  min-width: 100%;
}

.apply-all{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px 0px;
  background: #00968830;
  padding: 10px 10px;
  border: 1px solid #00968857;
  align-items:center;
  border-radius: 4px;
}

.apply-all-text{
  p{
    margin-top: 3px;
    font-size: 13px;
  }
}

.apply-all-btn{
  margin: 0px 10px;
  .ant-switch{
    background: #9E9E9E;
  }
  &.inline{
    display: inline-block;
  }
}

.edit-print-modal-title-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.apply-all-container{
  display: inline;
}

.edit-print-options{
  .btn--dark-blue.btn--primary svg path {
    fill: #4CAF50;
  }
}

.pagination-btn-container{
  display: flex;
}

.edit-print-modal-section-divider{
  width: 100%;
  height: 1px;
  margin: 15px 0px;
  background: #cecece;
}

.edit-print-modal-button-section{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}