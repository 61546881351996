.checkout__guest-or-customer{
  h2{
    font-size:14px;
    font-weight: 400;
  }

  p{
    font-size: 14px;
  }

  .checkout_options-container{
    margin: 20px 0px;
    .btn{
      margin-bottom: 5px;
    }
  }

  .checkout__option-btn-explainer{
    margin-top:2px;
    margin-bottom: 20px;
    font-size: 13px;
  }

  .help-block{
    font-size: 11px;
    margin-top: 4px;
    color: rgba(51, 51, 51, 0.5);
    font-weight: 400;
    background: #ffc10747;
    color: black;
    padding: 7px;
    border-radius: 2px;
  }
}