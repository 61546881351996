@import "../../styles/abstracts/variables";

.shipping-summary__container{

}

.shipping-summary__section{
  margin-bottom: 20px;
  h2 {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h3{
    font-size: 12px;
  }

  .info-icon{
    font-size: 22px;
    margin-right: 2px;
  }

}

.shipping-summary__shipping-address{
  display: flex;
  justify-content: space-between;
  border: 1px solid #e9e9e9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s;
  cursor: pointer;
  align-items: center;
  flex-direction: row;
  align-items: flex-start;

  background: #cccccc0d;

  &:hover{
    //background-color: rgb(245, 245, 245);
    border: 1px solid #bbb;
  }
  p{
    white-space: pre-line;
    color: black;
    font-size: 13px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 0px;
  }
  .shipping-summary__shipping-address-phone{
    
    .shipping-summary__shipping-address-phone-input{
      display: flex;
    }

    .form-control{
      margin-right: 10px;
      width: 100%;
      display: block;
      border: 1px solid #e8e8e8;
      padding: 10px;
      font-size: 14px;
      font-family: $tt-font-stack-primary;
      border-radius: 3px;
      height: 32px;
      background-color: #fff;
      -webkit-appearance: none;
      &[disabled],
      &[readonly] {
        color: rgba($tt-color-text-dark, 0.7);
      }
    }
  }
}

.shipping-summary__shipping-options{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.shipping-summary__shipping-option{
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 7px 15px;
  margin: 5px;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s;
  cursor: pointer;
  //align-items: center;
  min-height: 80px;
  align-items: center;

  margin-bottom: 15px;

  &:hover{
    //background-color: #eee;
    border: 1px solid #bbb;
  }
}

.shipping-option__text{
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  .shipping-option__title{
    font-weight: 500;
    font-size: 14px;
  }
  .shipping-option__description{
    font-size: 12px;
    margin-right: 5px;
  }
}

.shipping-option__info{
  margin-left: 10px;
}

.shipping-option__bullet{
  display: flex;
  align-items: center;
}

.shipping-summary__extra-info{
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  background: #3B3938;
  color: #fff;
  border-radius: 4px;
  display: flex;
  p{
    color: #fff;
    font-size: 12px;
    display: inline-block;
  }
}

.info-icon{
  font-size: 17px;
  .anticon{
    border: 1px solid #5a5a5a;
    border-radius: 100%;
    padding: 3px;
  }
}