@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins/mq";

.promotion-code-input__input-wrapper {
  display: flex;

  .form-control {
    flex: 1;
    font-size: 1em;
    &:focus {
      border: 1px solid $tt-color-primary;
      -webkit-appearance: none;
      outline: none;
      outline-width: 0;
    }
  }
}

.promotion-code-text {
  text-align: center;
  font-weight: 500;
  color: $tt-color-success;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
}

.promotion-code-dismiss-text {
  text-align: right;
  float: right;
  font-weight: 500;
  color: $tt-color-success;
  cursor: pointer;
  margin-bottom: 0px;
  font-size: 12px;
}

.item-preview {
  position: relative;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
  &.shadowed {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
}

.scan-preview{
  width: 95%;
  max-width: 650px;
  margin: 20px auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.slick-dots li button:before{
  font-size: 14px !important;
  line-height: 14px !important;
}

.pack-preview{
  border: 1px solid #dfdfdf;
  background: white;
  &.single{
    border: 1px solid #dfdfdf;
    box-shadow: 2px -2px 3px 1px #f1f1f1;
    width: 100px;
  }
  &.shadow{
    box-shadow: 2px -2px 3px 1px #f1f1f1;
  }
}

.photo-tile-preview {
  width: 100px;
}

.upsell--magazine-container{
  position: relative;
  .upsell--intro-text{
    text-align: center;
    font-size: 12px;
    margin-bottom: 25px;
  }
  .upsell--footer-text{
    text-align: center;
    font-size: 12px;
    margin-top: 25px;
  }

  .upsell--promo-code{
    border: 1px solid #e1e1e1;
    padding: 5px 13px;
    color: #ff5a5f;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 1px 1px 13px 2px #d7d7d7;
  }

  .upsell--magazine-cover{
    .pagecurl-overlay{
      pointer-events: none;
      position: absolute;
      bottom: 0;
      right: 0;
      background-image: url("/app/images/pagecurl.png");
      background-size: cover;
      width: 20%;
      height: 20%;
    }


    .pagecurl {
      width:17%;
      height:17%;
      position: absolute;
      bottom:0;
      right:0;
      background :
        linear-gradient(
          135deg,
          #fff,
          #f3f3f3 45%,
          #ddd 50%,
          #aaa 50%,
          #bbb 56%,
          #ccc 62%,
          #f3f3f3 80%,
          #fff 100%
        );
      box-shadow : 0 0 10px rgba(0, 0, 0, .5);
      transition: all .5s ease;
    }
    .pagecurl:before,
    .pagecurl:after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 12.5%;
      bottom: 5.8%;
      width: 70%;
      max-width: 300px;
      max-height: 100px;
      height: 55%;
      box-shadow: 0 12px 15px rgba(0, 0, 0, .3);
      transform: skew(-10deg) rotate(-6deg);
    }
    .pagecurl:after {
      left: auto;
      right: 5.8%;
      bottom: auto;
      top: 14.16%;
      transform: skew(-15deg) rotate(-84deg);
    }

    .pagecurl:hover {
      width: 22%;
      height: 22%;
    }
    .pagecurl:hover:before,
    .pagecurl:hover:after {
      box-shadow: 0 24px 30px rgba(0, 0, 0, .3);
    }
    position: relative;
    width: 250px;
    height: 250px;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    box-shadow: 3px 2px 9px 3px #d1d1d1;
    .upsell--magazine-band{
      position: absolute;
      bottom: 12.5%;
      background: white;
      width: 100%;
      text-align: center;
      height: 12.5%;
      font-size: 60%;
      padding: 4px;
      font-weight: 600;
      left: -1px;
    }
  }

}

.spacer{
  height:10px;
}

.qty-and-price{
  font-size: 12px;
  &.editing {
    display: none;
  }
}

.bag-item-editing{
  border: 2px solid #a5a5a536;

  transition: all ease 0.2s;
  .item-preview{
    vertical-align: top;
  }

  .crop-button{
    font-size: 12px;
    margin: 0 auto;
    display: block;
    margin-top: 10px;
    border-radius: 2px;
  }
  > td{
    padding-top: 10px;
    padding-bottom:10px;
    //background:#9393930f;
    transition: all ease 0.2s;
    // border: 1px solid blue;

    // border-top: 10px solid;
    // border-bottom: 10px solid;
    // border-color: transparent;
  }
}

@include mq($from: desktop) {
  .bag-item-editing{
    .crop-button{
      //left: 57%;
    }
  }
}

.basket__block-title{
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  display: flex;
  justify-content: space-between;

  background: #f4f4f4;
  padding: 6px 8px;
  width: auto;
  display: inline-block;
  /* margin: 10px 0px; */
  border-radius: 4px;
}

.basket__block-title-right{
  display: inline-block;
  float: right;
  clear: both;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  justify-content: unset;
  margin-top: 7px;
}

.basket__summary-container{
  margin-bottom:10px;

  .basket__summary-add-more-btn{
    //border: 1px solid #000;
    padding: 4px 8px;
    border-radius: 2px;
    font-size: 11px;
    cursor: pointer;
    margin: 10px;
    background: #4C5666;
    color: white;
    &:hover{
      //color: #4C5666;
      //background: white;
    }
  }

  .basket__summary-block{
    margin: 8px 0px;
    border: 1px solid #16161624;
    padding: 8px;
    border-radius: 2px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    //align-items: center;
    box-shadow: 1px 1px 2px 0px #d3d3d330;
  }

  .basket__summary-block-left{
    flex-grow: 1;
  }

  .basket__summary-top-row{
    display: flex;
    justify-content: space-between;
  }

  .basket__summary_row{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dotted #f0f0f0;
    padding: 10px;
    &.no-border{
      border:none;
    }
    &.bold{
      font-weight: 500;
      .basket__summary-text{
        font-weight: 500;
      }
    }
    &.border-top{
      border-top: 2px solid black;
      //border-bottom: 2px solid #ff5a5f69;
    }
    &.last{
      border:none;
    }
    &.colored{
      color: #009688;
    }
    &.row-divider{
      border-bottom: 2px solid black;
    }
  }

  .basket__summary_row--subheading{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    span{
      padding: 4px 6px;
      background: #f4f4f4;
      border-radius: 4px;
      margin: 4px 0px;
    }
  }

  .basket__summary-text{
    color: black;
    margin-bottom: 0px;
    max-width: 66vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.multiline-address{
      white-space: pre;
    }
  }

  .basket__summary-edit-btn{
    border: 1px solid #000;
    padding: 6px;
    border-radius: 2px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    &:hover{
      background: #4C5666;
      color: white;
    }
  }
}

.promo-invalid{
  background: #080808;
  color: white;
  text-align: center;
  display: block;
  padding: 7px;
  width: 60%;
  width: 1;
  font-size: 12px;
  border-radius: 2px;
  margin: 0 auto;
  min-width: 275px;
}
