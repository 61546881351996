.photo-print-preview {
  margin: 0 auto;
  padding: 10px;
  position: relative;
  background-color: #fff;
  transition: background-color 0.2s;
  max-height: 100%;
  img {
    display: block;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(#000, 0.3);
    transition: opacity 0.2s;
    opacity: 1;
    //image-rendering: pixelated;
    height: 95%;
    //margin-top: 10px;
  }

  &.photo-print-preview--fake-border{
    img{
      border: 10px solid white;
      padding: 0px;
      //box-shadow: 1px 1px 8px 1px #ccc;
    }
  }

  &.photo-print-preview--with-bleed-mask{
    img {
      box-shadow:none;
      width: auto; //100%
      height: 100%;
    }
  }

}

.photo-print-preview--loading {
  background-color: transparent;

  img {
    opacity: 0;
  }

  .photo-print-preview__loading-indicator {
    opacity: 1;
  }
}

.photo-print-preview__loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}

.photo-print-preview__bleed-mask{
  //width: calc(90% - 24px);
  //height: calc(90% - 24px);
  //top: 12px;
  //left: calc(5% + 12px);
  background: transparent;
  z-index: 2;
  position: absolute;
  max-height: 100%;
  border: 0px solid #ccc;
  //border: 0px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.photo-print-preview--with-bleed-mask{
  padding: 0px;
}

.photo-print-preview__head-check{
  position: absolute;
  padding: 2px 9px;
  border-radius: 2px;
  color: white;
  display: flex;
  justify-content: center;
  align-self: center;
  top: -65px;
  width: 100%;
  p{
    background: #009688;
    justify-self: center;
    padding: 4px 10px;
    color: white;
    border-radius: 2px;
  }
}

.approval-count{
  background: #f9f9f9;
  margin: 8px auto;
  margin-bottom: 12px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 14px;
  border-radius: 2px;
  font-weight: 400;
  border: 1px solid #efefef;
  border-radius: 20px;
  font-size: 12px;
}


.photo-print-previews-container{
  // display: flex;
  // justify-content: center;
  // align-items: center;
  //height: 500px;
  //height: 370px;
  height: 370px; //auto
  max-width: 70vw;
  margin: 0 auto;
}