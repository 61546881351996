@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins/icon-color";

.ant-progress-inner{
  background-color: #F3E0E4;
}

.ant-modal-footer {
  border: 0px;
  padding: 0px;
}
.ant-custom-modal {
  // width: 40vmax !important;
  top: 0 !important;
  margin: 0 !important;
}
.ant-custom-modal .ant-modal-content {
  width: 100vw !important;
}
.ant-modal-close {
  left: 0;
}
.ant-modal-wrap-text-editor {
  overflow: hidden;
}

.ant-tabs-tab-btn {
  padding: 5px 10px;
  border-radius: 2px;
  background: #F0F0F0;
  color: #454545;
  border: 1px solid #dedede;
}

.ant-tabs-tab {
  margin-right: 10px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background: #ff5a5f;
  color: white;
  padding: 5px 10px;
  border-radius: 2px;
  text-align: center;
}

.photo-magazine-prebag__page {
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  position: relative;

  .html-renderer-container {
    margin: 0 !important;
  }

  .photo-magazine-prebag__top-action{
    position: absolute;
    top: 15px;
    right: -5px;
    svg {
      path {
        fill: red;
      }
    }
  }

  .photo-magazine-prebag__actions{
    margin-top: 10px;
  }

  &.cover{
    background: #f8f8f8;
    padding: 20px 10px;
    border: 1px solid #ebebeb;
    margin-bottom: 20px;
    .html-renderer{
      box-shadow: 8px 8px 7px 0px #4b4b4b57;
    }
  }

  &.photo-magazine-prebag__page_even {
    border: 2px solid #e2e2e2;
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 10px;
    .photo-magazine-prebag__top-action{
      right: 5px;
    }
  }
  &.photo-magazine-prebag__page_odd {
    border: 2px solid #e2e2e2;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 20px;
    padding: 10px;
    position: relative;
    padding-bottom: 45px;
    .photo-magazine-prebag__top-action{
      right: 5px;
    }
  }

  .page-divider{
    width: calc(100% - 30px);
    border-top: 1px dashed #e2e2e2;
    position: absolute;
    top: 0px;
    left: 15px;
  }

  .spread-info {
    width: 100%;
    position: absolute;
    bottom: 11px;
    text-align: center;
    span {
      background: #0096881c;
      border-radius: 3px;
      padding: 5px 8px;
      font-size: 10px;
      border: 1px solid #00968824;
    }
  }
}

@media (min-width: 576px) {
  .page-divider{
    display:none;
  }
}

.page-shadow {
  box-shadow: 0 0 5px #00000040;
}

.global-btn {
  padding: 5px;
}

.icon-active {
  svg {
    path, circle {
      fill: #4CAF50;
    }
  }
}

.templateSelectModal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  //grid-auto-rows: 260px;
  gap: 40px;
  overflow: scroll;
  padding: 10px 15px;
  //height: 80vh;
  //align-items: center;
}

.photo-magazine-prebag__page-indicator {
  display: flex;
  align-items: center;
  margin-bottom: $tt-spacing-unit-small;
  padding: $tt-spacing-unit-small;
  flex-direction: column;
  position: relative;
  //border: 1px solid #4b5566;
  //color: #4b5566;

  p {
    flex: 1;
    padding-right: $tt-spacing-unit-default;
    margin-bottom: 0;
    color: rgba(51, 51, 51, 0.8);
    font-size: $tt-font-size-small;
    text-align: center;
    font-weight: 300;
  }

  .btn {
    background-color: #fff;
    color: #043253;
  }

  .btn__label {
    font-size: $tt-font-size-small;
  }

  &.complete {
    //background-color: $tt-color-success;
  }
}

.photo-magazine-prebag__drag-handle {
  display: inline-flex;
  //align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  opacity: 0.5;
  transition: all 0.2s;
  margin: 0px 4px;
  &.less-opacity {
    opacity:0.8;
  }
}

.photo-magazine-prebag__header-icon {
  display: inline;
  margin-left: 10px;
}

.photo-magazine-prebag__page-number {
  //font-size: 0.8rem;
  font-size: 14px;
  // width: 16%;
  margin-right: 10px;
}

.photo-magazine-prebag__actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0px;

  .photo-magazine-prebag__actions-container{
    border: 1px solid #f2f2f2;
    background: #fcfcfc;
    position: relative;
    padding-top: 12px;
    display: flex;

    .photo-magazine-prebag__actions__help-title{
      position: absolute;
      top: -10px;
      left: 23px;
      background: white;
      font-size: 11px;
      padding: 0px 10px;
      border: 1px solid #e4e4e4;
      border: 0px;
    }
  
  }

  .btn-primary {
    margin-bottom: 0px;
    margin-left: 10px;
  }

  .photo-magazine-prebag__delete-button {
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 50%;
    background: transparent;
    border: 1px solid $tt-color-primary;
    @include iconColor($tt-color-primary);

    svg {
      width: 1rem;
      height: 1rem;
    }

    .btn__icon {
      height: 1.5rem;

      .icon {
        top: 0;
      }
    }
  }
}

.photo-magazine-prebag__help-text {
  font-size: 11px;
  text-align: center;
  padding: 5px 0px;
  border: 1px solid #f2f2f2;
  margin: 5px 10px;
  background: #fcfcfc;
  position:relative;

  .photo-magazinge-prebag__help-title{
    position: absolute;
    top: -10px;
    left: 23px;
    background: white;
    font-size: 11px;
    padding: 0px 10px;
    border: 1px solid #e4e4e4;
    border: 0px;
  }

  .icon {
    //position: relative;
    //margin-right: 4px;
    svg {
      top: 5px;
      position: absolute;
      path {
        fill: #000000; // rgba(129, 129, 129, 0.8);
      }
    }
  }
}

.coverText-label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.cover-text-input {
  font-family: "Crimson Text";
  &.dark {
    &::placeholder {
      color: #868686a6;
    }
  }
  &.light {
    &::placeholder {
      color: white;
    }
  }
}

@media (max-width: 576px) {
  // .ant-custom-modal {
  //   width: 50vmax !important;
  // }
  .templateSelectModal {
    //grid-template-columns: 1fr;
  }
  .photo-magazine-prebag__actions {
    //grid-area: actions;
    grid-area: auto;
  }

  .photo-magazine-prebag__page-number {
    grid-area: number;
    margin-left: 2px;
    //font-weight: bolder;
    position: absolute;
    top: 18px;
    left: 8px;
    grid-area: none;
    font-size: 12px;
  }
  .photo-magazine-prebag__preview {
    grid-area: pages;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .photo-magazine-prebag__page {
    display: grid;
    // grid-template-columns: auto auto ;
    // grid-template-rows: auto auto;
    grid-template-areas: "number  actions" "pages pages";
    // grid-template-rows: 0.2fr 1fr;
    padding-top: 30px !important;
  }
}

.drawer-content {
  margin: 0px 10px;
  padding-bottom: 30px;
  .drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    h2 {
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      margin: 0 auto;
    }
  }
  .drawer-body__info {
    background-color: #e5e5e5;
    margin: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    max-width: 400px;
    h4,
    p {
      color: white;
    }
    p {
      margin-bottom: 0px;
    }
    &.white {
      background-color: #eeeeee !important;
      h4,
      p {
        color: inherit;
      }
      .html-renderer-container {
        box-shadow: 2px 2px 8px 2px #0000003b !important;
      }
    }
  }

  .coverPreviewContainer {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background: white;
    position: relative;
    .html-renderer-container {
      box-shadow: 2px 2px 8px 2px #00000080;
    }
  }

  .coverArrowContainer{
    position: absolute;
    bottom: 0;
    left: 0;
    svg{

    }
  }

  // .coverPreviewContainer:after {
  //   content: "";
  //   position: absolute;
  //   top: 100%;
  //   bottom: 0;
  //   width: 120%;
  //   height: 11px;
  //   left: -10%;
  //   right: -10%;
  //   background: radial-gradient(ellipse at 50% -3%, rgba(0, 0, 0, 0.5), rgba(97, 97, 97, 0) 40%);
  // }

  // .coverPreviewContainer.cover:after {
  //   display:none
  // }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      #ffffff00 0%,
      #00000057 2%,
      #ffffff80 3%,
      #f7feff80 3%,
      #00000017 4%,
      #ffffff1f 11%,
      #ffffff00 100%
    );
    //background: linear-gradient(90deg, rgba(255,255,255, 0) 0%, rgba(0,0,0,0.43) 2%, rgba(255,255,255,.5) 3%, rgba(247,254,255,.5) 3%, rgba(0,0,0,7%) 5%, rgba(255,255,255,22%) 8%, rgba(255,255,255,0%) 100%);
    z-index: 1;
    &.overlay-back {
      transform: scale(-1, 1);
    }
  }
}
