@import "../../styles/abstracts/variables";

.loading-img{
  background: transparent url("/app/images/loading-img.gif") no-repeat scroll center center;
  background-size: 50px;
  //width: 100%;
  //scale: 0.2;
}

.photo-magazine-preview{
  width: 100%;
}

.photo-magazine-preview__render {
  position: relative;

  .shadow {
    -webkit-transition: -webkit-box-shadow 0.5s;
    -moz-transition: -moz-box-shadow 0.5s;
    -o-transition: -webkit-box-shadow 0.5s;
    -ms-transition: -ms-box-shadow 0.5s;

    -webkit-box-shadow: 0 0 20px #ccc;
    -moz-box-shadow: 0 0 20px #ccc;
    -o-box-shadow: 0 0 20px #ccc;
    -ms-box-shadow: 0 0 20px #ccc;
    box-shadow: 0 0 20px #ccc;
  }

  .page {
    position: relative;
    background-size: cover;
    background-position: center center;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    .item-preview{
      width: 100%;
      height: 100%;
    }
    &.left{
      box-shadow: -5px 0px 20px 1px rgba(0, 0, 0, 0.2);
    }
    &.right{
      box-shadow: 5px 0px 20px 1px rgba(0, 0, 0, 0.2);
    }
    &:after{
      content: "";
      position: absolute;
      /* z-index: -1; */
      top: 100%;
      bottom: 0;
      width: 120%;
      height: 11px;
      left: -10%;
      right: -10%;
      background: radial-gradient(ellipse at 50% -3%, rgba(0, 0, 0, 0.5), rgba(97, 97, 97, 0) 40%);
    }
    &.not-inner{
      &:after{
        display:none;
      }
    }
  }

  .page--blank {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: #fff;
  }

  .page__blank-label {
    color: rgba(#000, 0.6);
    font-size: 0.7rem;
  }

  .page__number {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    color: #fff;
    background: rgba(#000, 0.8);
    padding: 1rem;
  }
}

.photo-magazine-preview__navigation {
  margin-top: $tt-spacing-unit-default;
}