@import "../../styles/abstracts/variables";

.prepay-banner {
  background-color: $tt-color-dark-blue;
  color: $tt-color-text-light;
  text-align: center;
  padding: $tt-spacing-unit-default;
}

.prepay-banner__title {
  margin-bottom: 3px;
}

.prepay-banner__balance {
  font-size: $tt-font-size-large;
}
