.guest-checkout-capture{
  h2{
    font-size:16px;
    font-weight: 400;
  }

  p{
    font-size: 12px;
  }

  .help-block{
    font-size: 11px;
    margin-top: 4px;
    color: rgba(51, 51, 51, 0.5);
    font-weight: 400;
    background: #ffc10747;
    color: black;
    padding: 7px;
    border-radius: 2px;
  }
}