.preview-box {
  padding: 10px;
  margin: 10px;
}
.page-number {
  display: flex;
  align-items: center;
  padding: 4px;
  color: rgba(51, 51, 51, 0.8);
  font-weight: 300;
  // width: 100%;
}
.odd-page{
  justify-content: flex-end;
}
.even-page{
  justify-content: flex-start;
}

.book-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

}

.page-container {
  display: flex;
  flex-direction: column;
}

.book-container .page-container:nth-child(odd) {
  // align-items: flex-end;
}

.book-container .page-container:nth-child(even) {
  // align-items: flex-start;
}

.pageContainer{
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: white;
  position: relative;
}
.pageContainer:after {
  // background: white;
  // position: absolute;
  // content: "";
  // left: 0;
  // right: 0;
  // bottom: -12px;
  // height: 16px;
  // border-radius: 50% 50% 0 0;
  // border-top: 2px solid #cfcfcf;
  // content: "";
  // position: absolute;
  // z-index: -1;
  // top: 100%;
  // bottom: 0;
  // width: 120%;
  // height: 50px;
  // left: -10%;
  // right: -10%;
  // background: -webkit-radial-gradient(50% -3%, ellipse cover, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 40%);
  // background: radial-gradient(ellipse at 50% -3%, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 40%);
  content: "";
  position: absolute;
  /* z-index: -1; */
  top: 100%;
  bottom: 0;
  width: 120%;
  height: 11px;
  left: -10%;
  right: -10%;
  background: radial-gradient(ellipse at 50% -3%, rgba(0, 0, 0, 0.5), rgba(97, 97, 97, 0) 40%);
}

.pageContainer:before {
  // background: white;
  // position: absolute;
  // content: "";
  // left: 0;
  // right: 0;
  // top: -3px;
  // height: 16px;
  // border-radius: 50% 50% 0 0;
  // border-top: 1px solid #cfcfcf;
}