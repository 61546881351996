@import "../../styles/abstracts/variables";

.editor__collage-option {
  outline: 0;
  border: 0;
  margin-right: 15px;
  background: #ffffff;

  &:last-child {
    margin-right: 0;
  }

  svg {
    rect {
      fill: #ccc;
      stroke: #fff;
      stroke-width: 2px;
      transition: fill 0.2s;
    }
  }

  &.editor__collage-option--large{
    svg {

      border: 1px solid #c9c9c996;
      padding: 2px;
      box-shadow: 1px 1px 9px 3px #ccc;
  
      rect {
        fill: #ccc;
        stroke: #fff;
        stroke-width: 4px;
        transition: fill 0.2s;
      }
    }

  }
}

.editor__collage-option:hover {
  svg {
    rect {
      fill: #ccc;
    }
  }
}

.editor__collage-option--active {
  svg {
    rect {
      fill: $tt-color-dark-blue !important;
    }
  }
}
