@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins/mq";

.ReactModalPortal {
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.gsa {
  .modal-overlay {
    bottom: 130px;
  }
  .modal {
    //height: calc(100% - 130px);
  }
}

.modal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  outline: 0;
  overflow: hidden;
  background-color: rgba(#9b9b9b, 0.9);

  &.animated {
    animation-duration: 0.6s;
  }

  &.fadeInUp {
    box-shadow: 0 -10px 10px rgba(#000, 0.1);
  }
}

.modal-overlay--minimal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  .modal {
    height: auto;
    width: 100%;
    border-radius: 0.25rem;
  }
}

.modal-overlay--before-close {
}

.modal {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: background-color 0.2s;

  &:focus {
    outline: 0;
  }

  // @include mq($from: desktop) {
  //   height: calc(100% - #{2 * $tt-spacing-unit-default});
  //   margin: 0 auto;
  //   margin-top: $tt-spacing-unit-default;
  //   max-width: $tt-max-app-width - $tt-sidebar-width;
  //   box-shadow: 0 0 10px rgba(#000, 0.2);
  // }
}

.modal__content,
.modal__page {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;

  .width-limiter {
    display: flex;
    flex-direction: column;
  }
  @include mq($from: desktop) {
    margin: 0 auto;
    max-width: $tt-max-app-width - $tt-sidebar-width;
    min-width: 50vw;
  }
}

.modal__content {
  position: relative;
}

.modal__page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  will-change: transform;
}

.modal__page--animated {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  will-change: transform;
  transition-delay: 1ms;
}

.modal__content--padded {
  &,
  .modal__page {
    padding: $tt-spacing-unit-default;
  }
}


.modal__content--full-width {
  width: 100%;
}


.restricted-width-modal-content{
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: stretch;
  margin: 0 auto;
}

.faint-background-content{
  background: #f5f5f582;

  @include mq($from: desktop) {
    background: #fbfbfba6;
    border-left: 1px solid #cccccc47;
    border-right: 1px solid #cccccc47;
    border-bottom: 1px solid #cccccc47;
  }
}