@import "../../styles/abstracts/variables";

.footer {
  .btn,
  .grid-row {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.footer--floating {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: calc(#{$tt-max-app-width} - #{$tt-sidebar-width});
}

.footer--padded {
  padding: $tt-spacing-unit-small;
}

.footer--top-shadow {
  z-index:1;
  box-shadow: 0 0 9px 3px #ececec;
  clip-path: inset(-15px 0px 0px 0px);
}

.footer--bg {
  background: #fcfcfc;
  border-top: 1px solid #ebebeb;
}

.footer__split-buttons {
  .btn {
    padding: 0;
    margin: 0;

    &:first-child {
      width: 40%;
      margin-left: 8%;
    }

    &:last-child {
      width: 40%;
      margin-left: 5%;
    }
  }
}
