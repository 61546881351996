@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins/icon-color";

.order-summary{
  border-top: 1px solid #efefef;
  padding-top: 10px;
}

.order-summary--heading{
  font-size: 14px;
  color: rgba(51, 51, 51, 0.8)
}

.order-summary--section{
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 10px 10px;
  box-shadow: 1px 1px 7px 1px #e8e8e8;
}

.order-summary--product-type-section{
  margin-left: 10px;
  margin-top: 10px;

  font-size: 12px;
  margin-bottom: 5px;
  // border-bottom: 1px solid #ececec;
  //border-top: 1px solid #ececec;
  
  padding-top: 5px;
  .order-summary--product-type-heading{
    font-weight: 400;
  }
  &.shipping{
    padding-top: 0px;
    display: flex;
    justify-content: space-between;
  }
}


.order-summary--product-groupings{
  margin-left: 5px;
  p{
    margin-bottom: 5px;
  }
}

.order-summary--product-shipping-options{
  margin-bottom: 0px;

  &.multiple{
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin: 10px;
  }

  margin: 10px 2px;
  padding: 5px;
  border-radius: 2px;

  p {
    font-size: 12px;
    color: black;
    margin: 5px 0px;
  }

  // Disable antd button animations for radios as they have line height issues
  .ant-radio-checked::after{
    //animation: none !important;
    visibility: hidden !important;
    display: none;
  }

  &.single-option{
    .ant-radio-checked .ant-radio-inner{
      border-color: #333 !important ;
    }
    
    .ant-radio-checked .ant-radio-inner:after{
      background-color: #333;
    }
    
    .ant-radio:hover .ant-radio-inner {
      border-color: #333 ;
    }
  }

}


.order-summary--grand-total{
  font-weight:500;
  font-size: 14px;
}


.shipping-cost-header{
  //margin-right: -16px;
  font-weight: 400;
  text-align: right;
  overflow: visible;
  color: rgba(51, 51, 51, 0.8);
  font-weight: 300;
  font-size: 12px;
}

.pricing-text{
  color: rgba(51, 51, 51, 0.8);
  font-weight: 300;
  display: block;
  text-align: right;
  font-size: 12px;
}