@import "../../styles/abstracts/variables";

.product-type-landing-page__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: $tt-spacing-unit-default;
  grid-row-gap: $tt-spacing-unit-default;
  grid-auto-rows: minmax(min-content, max-content);
}

.product-type-landing-page__category-title {

}

.product-type-landing-page__product {
  padding: 0px; //$tt-spacing-unit-default;
  border-radius: 2px;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 6px rgba(#000, 0.3);
  transition: all .2s;
  flex-direction: column;
  overflow: hidden;
  &:active {
    box-shadow: 0 0 10px rgba(#000, 0.5);
  }
}

.product-type-landing-page__product-dimensions-indicator {
  background: rgba($tt-color-text-dark, 0.2);
  margin-right: $tt-spacing-unit-default;
}

.product-type-landing-page__product-info {
  flex: 1;
  padding: 10px 0px; 
}

.product-type-landing-page__product-name {
  color: $tt-color-dark-blue;
  font-weight: $tt-font-weight-bold;
}

.product-type-landing-page__product-pricing {
  color: rgba($tt-color-text-dark, 0.6);
  font-size: $tt-font-size-small;
}
