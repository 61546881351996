@import "../../styles/abstracts/variables";

.address-book__character-container{
  height: 50px;
  background: #fbfbfb;
  border-top: 1px solid #cecece54;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.address-book__character {
  font-size: $tt-font-size-large;
  font-weight: bold;
  color: $tt-color-dark-blue;
  margin-left: $tt-spacing-unit-default;
}

.selected-address {
  line-height: 1.4;
  font-size: 13px;
  white-space: pre;
  color: black;
  border: 1px solid #e5e5e5;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
}


.address-book__subheading{
  font-size: 15px;
  font-weight: 500;
  color: #4a4a4a;
  margin-left: 10px;
}

.my-address-entry{
  .block{
    background: #c3e4ff2e;
    color: white;
    border-top: 1px solid #eee;
  }
}