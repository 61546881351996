@import "../../styles/abstracts/variables";

.ant-modal-footer {
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}

.ant-tooltip-inner{
  position: relative;
}

// .ant-modal-body {
//   padding: 0px;
// }

.crop-notice{
  background: #ffca070f;
  padding: 5px 12px;
  border: 1px solid #5444162e;
  margin-bottom: 15px !important;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  .quote-and-icon{
    display:flex;
    //align-items: center;
  }

  .icon--full-width{
    &,
    svg {
      // width: 50px;
      // height: 50px;
      // padding: 5%;

      width: 21vw;
      height: auto;
      padding: 2px;
      margin: 5px;
      max-width: 90px;
      max-height: 90px;

      path {
        fill: #4C5666;
        opacity: 1;
      }
    }
  }

  .lab-quote{
    font-size: 1.4em;
    width: auto;
    margin: 5px auto;
    font-style: italic;
    color: #555555;
    padding: 1em 10px 1em 27px;
    border-left: 3px solid #4C5666;
    line-height: 1.6;
    position: relative;
    background: white;
    text-align: left;
    box-shadow: 2px 0px 10px 0px #ccc;
    display: flex;
    align-items: center;
    border-radius: 5px;

    strong{
      font-weight: bold;
      text-decoration: underline;
    }
    &::before{
      font-family:Arial;
      content: "\201C";
      color:#4C5666;
      font-size:3em;
      position: absolute;
      left: 0px;
      top:-18px;
    }
    
    &::after{
      content: '';
    }
    
    & > span{
      display:block;
      color:#333333;
      font-style: normal;
      font-weight: bold;
      margin-top:1em;
    }
  }
}

.photo-prints-prebag__no-items-container{
  // height: 100vw;
  // display: flex;
  max-height: 280px;
  max-width: 280px;
  padding-top: 10%;
  margin: 0 auto;
}

.photo-prints-prebag__no-items{
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color .3s;
  transition: border-color .3s;
  width: 280px;
  height: 280px;
  border-color: #4C5666;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  margin: 0 auto;
  max-height: 280px;
}

.photo-prints-prebag__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 5px;
  position: relative;
  flex-basis: 50%;

  &:last-child {
    //margin-bottom: 0;
  }

  .item-options{
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;

    .item-options__delete{
      margin-top:2px;
      path{
        fill: #F95E65;
      }
    }
  }

  .quantity-picker {
    //margin-left: auto;
  }

  .html-renderer-container {
    //margin: 0;
    //margin-right: 2rem;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.16);

    .html-renderer-photo-layer__border.lower-canvas {
      left: 0px !important;
      top: 0px !important;
      transform: scale(1.02);
    }
  }

  .photo-prints-prebag__item-preview{
    margin-bottom: 10px;
    //transition: transform 0.2s ease-in-out;
    &.item-preview--padded{
      padding: 1px;
    }

    &.item-preview--rotated{
      transform: rotate(90deg);
    }

    &.item-preview--is-glossy-or-matt{
      overflow: hidden;
      position: relative;
      padding: 5px 5px;
      .glossy-or-matt {
        width: 55px;
        height: 150px;
        position: absolute;
        top: -20px;
        left: -15vh;
        transition: 1s;
        transform: rotate(30deg);
        background: linear-gradient(90deg, rgba(255,255,255, 0)50%, rgba(255,255,255,0.7)100%);
      }
    }

    &.item-preview--is-glossy {
      .glossy-or-matt {
        transform: rotate(0);
        left: 60vw;
        top: 0px;
        transition: 1s ease;
        height: 150px;
      }
    }
  }

}

.low-res-warning{
  text-align: center;
  // position: absolute;
  // bottom: 27%;
  // left: 160px;
}

.low-res-warning-content{
  font-size: 12px;
  width: 50vw;
  .ant-btn:focus{
    color: rgba(0, 0, 0, 0.65);
    border-color: #d9d9d9;
  }
}

@media (max-width: 360px) {
  .low-res-warning {
    bottom: -4px;
    right: 41px;
    left: unset;
    transform: scale(0.75);
  }
}

.photo-prints-prebag__pricing-indicator {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 - $tt-spacing-unit-small;
  margin-bottom: $tt-spacing-unit-small;
  padding: 8px 4px;
  //background-color: #043253;
  // background-color: #ffffff;
  // border: 1px solid #e9e9e9;
  background-color: #f7f7f7de;
  border: 1px solid #dadada;
  //border-radius: 6px;
  //box-shadow: 2px 2px 5px 0px #e1e1e1;
  //border-radius: 10px;
  //max-width: 500px;
  margin: 0px auto;
  margin-bottom: 7px;

  .photo-prints-prebag__pricing-breakdown{
    font-size: 12px;
    padding: 0px 5px;
  }

  p {
    flex: 1;
    //padding-right: $tt-spacing-unit-default;
    margin-bottom: 0;
    font-size: 0.8rem;
    text-align: left;
    line-height: 22px;
    //font-size: $tt-font-size-small;
  }

  .btn {
    background-color: #fff;
    color: #043253;
  }

  .btn__label {
    font-size: $tt-font-size-small;
  }
}

.print-options-container{
  margin-bottom: 15px;
  padding: 0px 10px;
  margin-top: -20px;
}

.edit-options-container{
  margin-bottom: 15px;
  padding: 0px 10px;
  margin-top: 0px;
  .icon{
    position: relative;
    svg{
    position: absolute;
    top: 3px;
    left: -1px;
    }
  }
}

.print-options-container--basket{
  font-size: 11px;
}

.crop-info{
  margin-top: 41vh;
  .ant-message-notice-content{
    //background: #4b5566;
    //color: white;
    font-size: 14px;
  }
}
// .horizontal-scroller{
//   border: 1px solid #d9d9d9;
//   border-radius: 4px;
//   padding: 4px;
//   margin-bottom: 12px;
// }
  