@import "../../styles/abstracts/variables";

.bottom-navbar__item {
  padding: 8px 5px;
  border: 0;
  outline: 0;
  background-color: transparent;
  text-align: center;
  flex: 1;
  > * {
    pointer-events: none;
  }
}

.bottom-navbar__item--active {
  color: $tt-color-primary;

  svg {
    path,
    circle {
      fill: $tt-color-primary;
    }
  }
}

.bottom-navbar__item__label {
  margin-top: 2px;
  font-size: 11px;

  // @media screen and (max-height: 421px) {
  //   display: none;
  // }
  @media screen and (max-height: 332px) {
    display: none;
  }
}
