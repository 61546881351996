.image-dnd-preview {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 3px solid #fff;
  box-shadow: 0 0 5px rgba(#000, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity .4s, transform 0.4s;
  transform: translate(-50%, -50%) scale(0.5);
}

.image-dnd-preview--visible {
  opacity: 0.6;
  transform: translate(-50%, -50%) scale(1);
}
