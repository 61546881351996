@import "../../styles/abstracts/variables";

$editor-signature-color-option-dimensions: 40px;
$checkmark-image-width: 28px;
$checkmark-image-height: 23px;

.editor__signature-color-option {
  width: $editor-signature-color-option-dimensions;
  height: $editor-signature-color-option-dimensions;
  display: inline-block;
  margin-right: $tt-spacing-unit-default;
  border-radius: 50%;
  background-size: cover;
  background-color: #fff;
  border: 2px solid #eee;
  position: relative;
  transition: border-color 0.2s;

  &:last-child {
    margin-right: 0;
  }
}

.editor__signature-color-option--active {
  border-color: #fff;

  .editor__signature-color-option__checkmark {
    transform: scale(1);
  }
}

.editor__signature-color-option__checkmark {
  position: absolute;
  top: calc(50% - #{$checkmark-image-height * 0.5});
  left: calc(50% - #{$checkmark-image-width * 0.5});
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}
