@import "../../styles/abstracts/variables";

.rc-slider-handle {
  margin-left: -4px;
  margin-top: -7px;
  width: 18px;
  height: 18px;
  border-color: $tt-color-dark-blue;

  &:hover,
  &:active {
    border-color: darken($tt-color-dark-blue, 10%);
  }

  &:active {
    box-shadow: 0 0 5px darken($tt-color-dark-blue, 10%);
  }
}

.rc-slider-track {
  background-color: $tt-color-dark-blue;
}
