.container {
    display: flex;
    // width: 700px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .container:global(.draggable-container--is-dragging) {
    cursor: move !important;
  }
  
  .container:global(.draggable-container--is-dragging) * {
    cursor: move !important;
  }
  
  .containerItem {
    // width: 350px;
    // height: 350px;
    min-height: 100px;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    transition: 0.5s background-color;
  }
  
  .containerItem:hover {
    opacity: 0.6;
  }
  
  .containerItem:global(.draggable-mirror) {
    opacity: 0.8;
  }

  .containerItem:global(.draggable-source--is-dragging) {
    opacity: 0;
  }