@import "../../styles/abstracts/variables";

.crop-check-popup {
  .modal{
    background-color: #F8E8EA;
  }
  

  .modal__content {
    text-align: center;
  }

  .buttons{
    display: flex;
    justify-content: center;
  }

  .crop-img{
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  h3{
    font-size: 26px;
    font-weight: 600;
    color: #3E3E3E;
  }

}