@import "../../styles/abstracts/variables";

$html-renderer-layer-z-indexes: (
  text: 5,
  placeholder: 5,
);

.html-renderer-container {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
}

.html-renderer-container--no-border {
  box-shadow: none !important;
}

.html-renderer-container--relative {
  position: relative;
}

.html-renderer-container--extra-envelope {
  position: absolute;
  left: 10px;
  bottom: -10px;
  > .html-renderer {
    background: #f3f3f3 !important;
    box-shadow: 3px 4px 12px 1px #f1f1f1;
    border: 1px solid #dcdcdc;
  }
}

.html-renderer-container--envelope {
  > .html-renderer {
    border: 1px solid #e0e0e0;
  }
}

.html-renderer {
  position: relative;
  user-select: none;
  width: 100%;
  transition: border 0s ease, filter 0.2s cubic-bezier(0.6, -0.28, 0.74, 0.05), background 0.4s ease-in-out 0s;
  // TODO: This needs to have no border delay for the bag renderer
  //transition: border 0.5s ease, filter 0.2s cubic-bezier(0.6, -0.28, 0.74, 0.05);
}

.html-renderer--non-interactive {
  border: 0px solid transparent;
  box-shadow: none;
  .html-renderer-rect {
    pointer-events: none;
  }
}

.html-renderer--fake-border {
  border: 5mm solid #ffffff; //5mm

  //box-sizing: content-box; // TODO - do this properly
  //width: 480px !important;
  &.html-renderer--with-shadow{
    box-shadow: 5px 5px 12px 5px #d2d2d299;
  }
}

.html-renderer--effects-black-and-white{
  .html-renderer-photo-layer{
    filter: grayscale(100%);
  }
}

.html-renderer-rect {
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.html-renderer-photo-layer-effect {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("/app/images/canvas-overlay.png");
  background-size: cover;
  opacity: 0.4;
}

.html-renderer-photo-layer__region {
  position: absolute;
  //background-color: #f7f7f7;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  //transition: all 0.3s ease-in-out 0s;
}

.crop-alert {
  .icon--crop-rotate {
    vertical-align: middle;
  }
}

.html-renderer-photo-layer__region--has-image {
  display: block;
}

.html-renderer-photo-layer__region__uncropped-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.html-renderer-photo-layer__region__uncropped-image-top-crop{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top; //top crop
  //image-rendering: -webkit-optimize-contrast;
}

.html-renderer-photo-layer__region__cropped-image {
  display: block;
  min-width: 0 !important;
  min-height: 0 !important;
  max-width: none !important;
  max-height: none !important;
}

.html-renderer-photo-layer__region__blank {
  width: 100%;
  height: 100%;
  background: white;
}

.html-renderer-photo-layer__region__placeholder-icon {
  width: 48px;
  height: 48px;
}

.html-renderer-photo-layer__region__image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
}

.html-renderer-photo-layer__region__dnd-hint {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: rgba(#fff, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: all .2s;
}

.html-renderer-photo-layer__region__dnd-hint--visible {
  opacity: 1;
}

.html-renderer-photo-layer__region__dnd-hint__inner {
  margin: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  background-color: rgba(#000, 0.8);
  color: #fff;
  text-align: center;

  @media all and (max-width: 481px) {
    padding: 0.25rem;
    font-size: 0.625rem;
  }
}

.html-renderer-photo-layer__border {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-size: 40px; // TODO
  pointer-events: none;

  &.lower-canvas {
    // Why this?
    left: -1px !important;
    top: -1px !important;
  }
}

.html-renderer-placeholder-layer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $tt-color-dark-blue;
  color: $tt-color-dark-blue;
  font-size: 1em;
  padding: $tt-spacing-unit-default;

  p {
    color: inherit;
  }
}

.html-renderer-mask {
  pointer-events: none;
}

.html-renderer-mask__line,
.html-renderer-mask__band,
.html-renderer-mask__corner {
  position: absolute;
  //https://stackoverflow.com/questions/50105780/elements-disappear-when-scrolling-in-safari-webkit-transform-fix-only-works-t
  -webkit-transform: translate3d(0,0,0); // prevents flickering when scrolling on iOS
}

.html-renderer-mask__corner {
  background-color: #fff;
}

.html-renderer-transparent__bleed__mask__band{
  background-color: #fff !important;
  // background-color: #ffffffad !important;
  position: absolute;
}

.html-renderer-transparent__bleed__mask__shadow__faint{
  background-color: transparent !important;
  position: absolute;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.19), 0 6px 9px 0px rgba(0, 0, 0, 0.23);
  //border: 1px solid #efefef;
  //box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)
}

.html-renderer-transparent__bleed__mask__shadow{
  background-color: transparent !important;
  // background-color: #ffffffad !important;
  position: absolute;

  // box-shadow: 1px 1px 1px 0px #999,
  //       2px 2px 1px 0px #888,
  //       3px 3px 1px 0px #777,
  //       4px 4px 1px 0px #666,
  //       5px 5px 1px 0px #555,
  //       6px 6px 1px 0px #444,
  //       7px 7px 1px 0px #333,
  //       8px 8px 3px 0px #303030;

  //box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)

  box-shadow: 1px 1px 3px 0px #999,
              2px 2px 1px 0px #888,
              3px 3px 1px 0px #777,
              4px 4px 1px 0px #666,
              5px 5px 1px 0px #555,
              6px 6px 1px 0px #444,
              7px 7px 1px 0px #3e3e3e,
              8px 8px 1px 0px #3a3a3a,
              9px 9px 1px 0px #333,
              10px 10px 10px 0px #000;
  // transition-delay: 2s;
  // transition: .5s all;
  // opacity: 0.75;
  // pointer-events: visible;
  &:hover{
    // pointer-events: none !important;
    // transition-delay: 0s;
    // opacity: 0.1;
    box-shadow: 1px 1px 3px 1px #999,
              2px 2px 1px 0px #888,
              3px 3px 1px 0px #777,
              4px 4px 1px 0px #666,
              5px 5px 1px 0px #555,
              6px 6px 1px 0px #444,
              7px 7px 1px 0px #3e3e3e,
              8px 8px 1px 0px #3a3a3a,
              9px 9px 1px 0px #333,
              10px 10px 13px 3px #000;
  }

}

.html-renderer-mask__band--horizontal {
  left: 0;
  right: -2px;
  background-color: #fff;
}

.html-renderer-mask__band--vertical {
  top: 0;
  bottom: 0;
  background-color: #fff;
}

.html-renderer-mask__band--border{
  background-color: #fff;
  border-color: #cacaca;
  border-style: solid;
  border-width: 0;
  z-index: 2;
}


@media (max-width: 768px) {
  .html-renderer-mask__band--border{
    //border-color: rgb(75, 75, 75);
    border-color: #cacaca; // rgb(87, 87, 87);
  }
}

.html-renderer-bleed__band{
  position: absolute;
}

.html-renderer-bleed__band--horizontal {
  left: 0;
  right: -2px;
  background-color: #fff;
}

.html-renderer-bleed__band--vertical {
  top: 0;
  bottom: 0;
  background-color: #fff;
}

.html-renderer-bleed__band--border{
  background-color: #fff;
  border-color: #c3c3c354;
  border-style: solid;
  border-width: 0;
  z-index: 2;
}

.html-renderer-mask__heavy-borders{
  > .html-renderer-bleed__band--border{
    border-color: #333;
  }
}

.html-renderer-mask__line--horizontal {
  left: 0;
  right: 0;
  height: 1px;
  border-top: 2px dashed #fff;
}

.html-renderer-mask__line--vertical {
  top: 0;
  bottom: 0;
  width: 1px;
  border-left: 2px dashed #fff;
}

.html-renderer-mask__line--vertical {
  &.placeholder {
    border-color: #d2d2d2;
  }
}

.html-renderer-mask__line--horizontal {
  &.placeholder {
    border-color: #d2d2d2;
  }
}

.html-renderer-border {
  pointer-events: none;
}

.html-renderer-border__band {
  position: absolute;
  //https://stackoverflow.com/questions/50105780/elements-disappear-when-scrolling-in-safari-webkit-transform-fix-only-works-t
  -webkit-transform: translate3d(0,0,0); // prevents flickering when scrolling on iOS
}


.html-renderer-graphic-layer,
.html-renderer-indicia-layer {
  background-size: cover;
  pointer-events: none;
}

.html-renderer-indicia-layer {
  pointer-events: none;
}

.html-renderer-text-layer {
  touch-action: none;
  z-index: map-get($html-renderer-layer-z-indexes, text);

  &:not(.html-renderer-text-layer--is-locked) {
    .html-renderer-text-layer__container {
      //padding: 1em; // We're using em-units here to make sure the padding is consistent across all render sizes
      //padding: 1%; //1%
      //line-height:normal;
    }
  }

  .html-renderer-text-layer--debug{
    border: 1px solid black;
    background: #ff000078;
  }

  .html-renderer-text-layer--highlighted{
    border: 1px solid black;
    background: #ff000078;
  }

  .html-renderer-text-layer--screenshot{
    .html-renderer-text-layer__content{
      overflow: visible; // prevent text being cut off
    }
  }
}

.html-renderer-text-layer__container {
  position: relative;
}

.html-renderer-text-layer--is-selected {
  .html-renderer-text-layer__container {
    border: 1px solid $tt-color-primary;
    margin: -1px;
    transition: background-color 0.5s ease;
  }

  .html-renderer-text-layer__control {
    display: block;
  }
}

.html-renderer-text-layer--is-moving {
  .html-renderer-text-layer__container {
    background: #dd345461;
  }
}

.html-renderer-text-layer--is-locked {
  .html-renderer-text-layer__container {
    height: 100%;
    width: 100%;
  }
}

.html-renderer-text-layer--is-centered{
  .html-renderer-text-layer__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.html-renderer-text-layer__control {
  width: 17px;
  height: 17px;
  position: absolute;
  display: none;
}

.html-renderer-text-layer__control--rotate {
  top: -12px;
  right: -12px;
}

.html-renderer-text-layer__control--resize {
  bottom: -12px;
  right: -12px;
}

.html-renderer-text-layer__control--delete {
  top: -12px;
  left: -12px;
}

.html-renderer-text-layer__control--edit {
  bottom: -12px;
  left: -12px;
}

.html-renderer-text-layer__content {
  width: 100%;
  overflow: hidden;
  height: 100%;
  resize: none;
  border: 0;
  background-color: transparent;
  user-select: none;
  pointer-events: none;
  white-space: pre-line;
  word-wrap: break-word;

  //line-height: 1em; //Potential fix to stop bottom text issues

  //padding: 1rem; // Ensures no scrollbar for small text heights

  //overflow: visible; // prevent text being cut off

  // &.html-renderer-text-layer__content{
  //   //center text vertically
  //   display: flex;
  //   place-content: center;
  //   flex-direction: column;
  // }

  //center text vertically
  // display: flex;
  // place-content: center;
  // flex-direction: column;

  //Or:
  // display: flex:
  // align-items: center;
  // justify-content: center;

  // &.html-renderer-text-layer__content--centered-text{
  //   display: flex:
  //   align-items: center;
  //   justify-content: center;
  // }

}

.html-renderer-text-layer__rotation-handle {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: skyblue;
  border: 2px solid #fff;
}

.html-renderer-signature-layer {
  display: flex;
  &.html-renderer-signature-layer__full_width_drawing{
    .html-renderer-signature-layer__avatar{
      display:none;
      width: 0;
    }
    .html-renderer-signature-layer__drawing{
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      //background-size: cover;
      //background-repeat: no-repeat;
      //background-position: center;
    }
  }
}

.html-renderer-signature-layer__avatar {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 2px;
  flex: 0 auto;
  border-radius: 50%;
}

.html-renderer-signature-layer__drawing {
  flex: 1;
  background-size: cover;
}

.html-renderer-debug-layer {
  pointer-events: none;
}

.html-renderer-debug-layer__layer-id {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  padding: 3px;
  opacity: 0.3;
}
