.collage-prints-prebag {

}

.collage-prints-prebag__layouts {
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  // grid-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
  }
  .num-photos{
    width: 100%;
    font-size: 16px;
    margin: 10px 10px;
  }
}

.collage-print-prebag__item {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  .quantity-picker {
    margin-left: auto;
  }

  .html-renderer-container {
    margin: 0;
    margin-right: 2rem;
  }
}


.swatch-container{
  border: 2px solid transparent;
  display: inline-block;
  margin-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 3px;
  .swatch{
    //border-radius: 50%;
    height: 50px;
    width: 50px;
    border: 1px solid #e0e0e0;
  }

  &.active{
    border: 2px solid #ff5a5f;
    //border-radius: 50%;
  }
}