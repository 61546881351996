@import "../../styles/abstracts/variables";

.approval-complete{
  .approval-complete__info{
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      padding-left: 5px;
    }
    svg > path{
      fill: $tt-color-green;
    }
  }

  .approval_complete__buttons{

  }
}

.related-products-container{
  display: flex;
  justify-content: center;
  align-items: stretch;

  .related-product__link{
    width: 100%;
    border: 1px solid #9c9c9c4d;
    padding: 0px;
    margin: 7px;
    box-shadow: 1px 1px 15px 2px #a8a8a82e;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    overflow: hidden;
    max-width: 33vw;
  }

  .related-product{
    
  }

  .related-product__img{

  }

  .related-product__content{
    height: auto;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    min-height: 90px;
    justify-content: space-evenly;

    .related-product__title{
      font-size: 13px;
      font-weight: 400;
      margin-top:5px;
    }

    .related-product__price{
      font-size: 12px;
    }
  }
}