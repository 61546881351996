//@import "~antd/dist/antd.css";

@import "abstracts/variables";
@import "abstracts/mixins/icon-color";
@import "abstracts/mixins/mq";
@import "base/layout";
@import "base/typography";
@import "inbox";

@import "~animate.css/animate.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "~pretty-checkbox/dist/pretty-checkbox.css";

@import "vendor/datepicker-overrides.scss";
@import "vendor/privy-overrides.scss";
@import "vendor/swal-overrides.scss";
@import "vendor/ladda";
@import "vendor/toastr";
@import "vendor/uploadcare";

.ant-select-dropdown-menu{
    margin-left:0px;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #fff;
    background-color: #ff5a5f;
    border-color: #ff5a5f;
}

.ant-select-auto-complete.ant-select-lg .ant-input {
    height: 44px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 18px;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
}

.ant-btn > span {
    pointer-events: none;
}