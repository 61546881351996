@import "../../styles/abstracts/variables";

.cropper-face-guides{
  top: 10%;
  left: 20%;
  width: 60%;
  height: 60%;
  border-style: solid;
  border-width: 5px 0px 5px 0px;
  border-color: #00bcd4;
  position: absolute;
  display: block;
  opacity: 0.8;
}

.cropper-face-guide{
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
  top: 0px;
  pointer-events: none;
}

.any-size-prints-container{


  // .html-renderer-photo-layer__region--has-image::before{
  //   content: ""; // ::before and ::after both require content
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   //background-image: linear-gradient(180deg, #ccc, transparent #ccc);
  //   background-image: linear-gradient(180deg, #868686, transparent, #868686);
  //   //opacity: .7;
  // }

  // -webkit-mask-image: url(/app/images/masks/oval-locket-no-outline.png);
  // -webkit-mask-size: 100%;
  // -webkit-mask-repeat: no-repeat;
  // -webkit-mask-position: center;

  /*background-size: contain, 100% 34%, 100% 34%;
  background-image: url(/app/images/masks/circle-locket-no-outline.png),linear-gradient(#818181a6, #818181a6),linear-gradient(#818181a6, #818181a6);
  background-position: center ,top, bottom;
  background-repeat: no-repeat, no-repeat, no-repeat;

  background-size: contain, 34% 100%, 34% 100%;
  background-image: url(/app/images/masks/circle-locket-no-outline.png), linear-gradient(rgba(129, 129, 129, 0.65), rgba(129, 129, 129, 0.65)), linear-gradient(rgba(129, 129, 129, 0.65), rgba(129, 129, 129, 0.65));
  background-position: center center, left top, center right;
  background-repeat: no-repeat, no-repeat, no-repeat;
  */

  .ant-switch{
    transform: scale(1.2);
  }

  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  margin-top: -5px;
  .cta-btn-container{
    width: 80vw;
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 10px;
    margin-top: 25px;
  }

  .cropper-container{
    .cropper-point{
      background-color: $tt-color-primary
    }

    .cropper-view-box {
      outline: 2px solid $tt-color-primary;
      outline-color: $tt-color-primary;
    }
  }
}

.crop-intructions{
  font-size: 12px;
  text-align: center;
  margin-top: 15px;
  img.crop-icon{
    width: 17px;
  }
}

.instructions{
  margin-bottom: 10px;
  font-size: 14px;
  color: black;
  font-weight: 500;
}

.no-size-available{
  border: 1px solid #ff000014;
  padding: 10px;
  font-size: 13px;
  color: black;
  border-radius: 4px;
  background: #ff00000d;
  margin-top: 10px;
  p{
    color: red;
  }
}

.frame-options{
  display: flex;
  align-items: center;
}

.frame-input{
  display: flex;
  flex-direction: column;
  margin: 5px 5px;
  .frame-input__label{
    font-size: 12px;
  }

  .frame-size-input{
    width:50px;
    padding: 5px;
    text-align: center;
    border-radius: 0px;
    border: 1px solid #ccc;
    //-webkit-user-select: none;
    -webkit-user-select: text;
    -webkit-appearance: none;
    -webkit-touch-callout: none;
    &:focus{
      border: 1px solid $tt-color-dark-blue;
    }
    &::selection{
      background: $tt-color-dark-blue;
    }
  }

  .frame-size-select{
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23000000'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 10px;
    //background-position: calc(100% - 20px) center;
    background-position: right .8em top 60%;
    background-repeat: no-repeat;
    background-color: white;
    -moz-appearance: none; 
	  -webkit-appearance: none; 
  	appearance: none;
    width:120px;
    padding: 5px;
    text-align: center;
    border-radius: 0px;
    border: 1px solid #ccc;
    margin-right: 15px;
    
  }
}

.print-options{
  margin-bottom: 15px;
  .print-options__heading{
    display: flex;
    justify-content: space-between;
    .print-options__left{

    }
    .print-options__right{
      margin-top: 7px;
      .print-options__spec{
        border: 1px dotted black;
        color: black;
        padding: 4px 9px;
        background: #f2f2f2;
        font-size: 12px;
        border-radius: 4px;
        box-shadow: 3px 2px 7px 0px #b3b3b394;
      }
    }
  }
}

.print-preview{
  margin-top: 15px;
  margin-bottom: 15px;

  .photo-edit-options{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .photo-edit-options__left{

    }

    .photo-edit-options__right{
      .btn{
        margin: 0px 2px;
        font-size: 11px;
        padding: 0px 5px;
      }
    }
  }

}

.tile-options, .locket-options{
  margin-top: 5px;

  width: 80vw;
  max-width: 600px;
  margin: 0 auto;

  .option-title{
    font-size: 14px;
    margin: 10px 0px;
    color:black
  }
  .info-icon {
    font-size: 17px;
    margin-left: 5px;
  }

  .tiled-text{
    margin-bottom: 10px;
    font-size: 14px;
    color: black;
    font-weight: 500;
  }

  .tiling-container{
    display: flex;
    align-items: center;
    .tiling-info{
      padding: 0px 10px;
      font-size: 12px;
      color: black;
      margin-left: 10px;
      p{
        margin-bottom: 0px;
      }
    }
  }
}

.other-options{
  display:flex;
  margin-top: 10px;

  .option-title{
    font-size: 12px;
    margin-bottom: 2px;
    color:black
  }

  .other-options__finish-options{
    
  }

  .other-options__qty-options{
    
  }

}


.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 16px - 2px); //gh issue #24891
}


#cropper-preview{
  position: fixed;
}

.any-size-prints-container{
  .cropper-modal{
    background-color: #464646
  }
}


.custom-print-wrapper{
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tools{
    text-align: center;
    margin-bottom: 10px;
    // position: absolute;
    // top: -38px;
    // left: calc(80vw - 100px);
  }

  .custom-print_wrapper__inner{
    padding: 0px 5px;
    margin: 15px 0px;
    &.zoomed{
      margin: 15px 0px;
    }
  }

  .react-transform-component{
    overflow: visible;
    border: 2px solid transparent;
    transition: border 0.1s ease-in;
  }
  .zoom-warning{
    display:none;
  }

  &.zoomed{
    .react-transform-component{
      border: 2px solid #ff5a5f42;
      overflow: hidden;
    }
    .trim-prints__containter{
      display:none;
    }
    .zoom-warning{
      display:inline-block;
      position: absolute;
      top: 55px;
      z-index: 1;
      background: #ff5a5f;
      color: white;
      padding: 2px 5px;
      border-radius: 4px;
      font-size: 12px;
      p{
        color: white;
      }
    }
  }
}

.trim-prints__containter{
  position: relative;
  margin: 0 auto;
  .trim-prints{
    position: absolute;
    bottom: -45px;
    width: 100px;
    background: transparent;
  }
}

.locket-options{
  // .option-title{
  //   font-size: 12px;
  //   margin-bottom: 2px;
  //   color:black
  // }
  .locket-shapes{
    .locket-shapes__list{
      list-style:none;
      display: flex;
      justify-content: center;
      margin: 0px;
      .locket-shapes__list-item{
        margin: 5px;
        border-radius: 4px;
        padding: 5px;
        box-shadow: 1px 1px 5px 1px #cccc;
        &.active{
          border: 1px solid #ff5a5f;
        }
        .locket-shapes__img{
          width: 40px;
        }
      }
    }
  }
}