@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins/icon-color";

.quantity-picker {
  display: inline-block;
  text-align: center;
  user-select: none;
}

.quantity-picker__main {
  display: flex;
  align-items: center;

  .btn {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 50%;
    background: transparent;
    border: 1px solid $tt-color-dark-blue;
    @include iconColor(#000);

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .btn__icon {
    height: 1.5rem;

    .icon {
      top: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

}

.quantity-picker__value {
  height: 32px;
  line-height: 30px;
  width: 42px;
  text-align: center;
  font-size: 16px;
}

.quantity-picker__delete-button {
  margin: 0 auto;
  margin-top: 0.5rem;
}

// .ant-popover-buttons{
//   .ant-btn{
//     height: 32px;
//   }
// }