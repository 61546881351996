@import "../../styles/abstracts/variables";

.poster-editor__designs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: $tt-spacing-unit-default;
  grid-row-gap: $tt-spacing-unit-default;
  grid-auto-rows: minmax(min-content, max-content);
}

.poster-editor__design-preview {

}

.poster-editor__design-preview__image {
  box-shadow: 0 0 6px rgba(#000, 0.1);
}
