.themes-modal-container{
    background-color: beige;
}
.ant-tabs-content-holder{
    overflow-y: scroll;
}
.ant-tabs-nav{
    margin-left: 16px !important;
    margin-right: 16px !important;
}

.active-colour-swatch{
    width: 60vw;
    height: calc(60vw);
    margin: 20px auto;
    max-width: 400px;
    max-height: 400px;
    transition: background 0.1s ease-in-out;
    &.bordered{
        border: 1px solid #ccc;
    }
}

.colour-swatches-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
}

.each-colour-swatch-container{
    margin:10px;
    border: 1px solid white;
    &.active{
        border: 1px solid black;
    }
}

.each-colour-swatch{
    height:70px;
    width:70px;
    margin:4px;
    
    &.bordered{
        border: 1px solid #ccc;
    }
}

.colour-info{
    padding: 13px;
    margin: 0px auto;
    width: 80%;
    border: 1px solid #efefef;
    border-radius: 4px;
    background: #f9f9f9;
}


.backgrounds-selection-container{
    width: 90%;
    margin: 0 auto;
}

.background-type-container{
    background: #f2f2f296;
    border-radius: 8px;
    padding: 15px;
    border: 1px solid #e2e2e2;
}

.background-swatch{
    height: 50px;
    width: 50px;
    border: 1px solid #e0e0e0;
}

.background-swatch-container {
    display: inline;
    margin-right: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 3px;
    border: 2px solid transparent;
    &.active{
        border: 2px solid black;
    }
}

.background-category-name{
    padding-left: 3px;
}

.background-type-heading{
    font-size: 18px;
}

.background-swatches{
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
    &.center{
        //justify-content: center;
        .background-swatch-container {
            margin: 4px !important;
        }
    }
}

.background-type-divider{
    border-top: 1px solid #e3e3e3;
    margin: 30px 0px 20px 0px;
}

.background-info{
    margin: 20px 0px;
}