.checkout__contact-details{
  h3{
    font-size: 16px
  }
  p{
    font-size: 14px
  }

  .opt-in-label{
    color: "rgba(51, 51, 51, 0.7)";
    font-size: 12px;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.form .error-detail {
  margin-left: 0px;
  margin-top: 5px;
  height: auto;
  padding: 10px;
  background-color: #ff00000a;
  border: 1px solid #ff000008;
  border-radius: 3px;
  list-style-type: none;
  margin-bottom: 0px;
}