.coloured-retros-container{
  background: rgba(204, 204, 204, 0.2196078431);
  // padding: 10px;
  // margin-top: -10px;
  // border-radius: 10px;
  margin-top: 0px;
  border-radius: 10px;
  padding-top: 20px;
}

.coloured-retros-heading{
  // font-size: 16px;
  // text-align: center;
  // margin: 10px 0px 15px 0px;
  // margin-top: -5px;
  font-size: 16px;
  text-align: center;
  margin: 10px 0px 15px 0px;
  margin-top: -5px;
  width: 100%;
  margin: 0 auto;
  //background: #00968812;
  padding: 7px 5px;
  //margin-bottom: 10px;
  //box-shadow: 1px 1px 4px 0px #ccc;
  margin-top: -10px;
  font-weight: 500;
  z-index: 1;
  //box-shadow: 0 0 4px 1px #d7d7d7;
  //clip-path: inset(0px 0px -15px 0px);
}

.warning{
  background: #ff980057;
  padding: 10px;
  border-radius: 4px;
  color: black;
  margin: 0px 20px 20px 20px;
  p {
    font-size: 13px;
    color: black;
  }
}

.static-heading{
  position: fixed;
  z-index: 9;
  background: #f4f4f4;
  /* padding: 10px; */
  margin-top: -10px;
  border-radius: 10px;
  width: 100%;
}