@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins/mq";

.horizontal-scroller {
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  padding: $tt-spacing-unit-default;
  @include mq($from: desktop) {
    //justify-content: center;
    //display: flex;
  }

  &.horizontal-scroller__center-content{
    display: flex;
    justify-content: center;
    > .editor__image-option{
      margin: 0px 8px;
      //border: 1px solid #ccc;
    }
  }

  &.horizontal-scroller__spaced{
    display: flex;
    justify-content: space-evenly;
  }

  &.horizontal-scroller__bordered{
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 12px;
  }
}
