@import "../../styles/abstracts/variables";

$header-height: 70px;

$header-mini-height: 45px;

.header {
  min-height: $header-height;
  max-height: $header-height;
  display: flex;
  justify-content: center;
  background-color: transparent;

  &:last-child {
    margin-bottom: 0;
  }

  .btn {
    margin-bottom: 0;
  }
}

.header__content {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  max-width: $tt-max-app-width; // - $tt-sidebar-width;
}

.header--theme-grey {
  background-color: #ffffff;
  border-bottom: 1px solid #f1f1f1;
}

.header--theme-mini {
  min-height: $header-mini-height;
  max-height: $header-mini-height;
}

.header__center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  pointer-events: none;
}

.header__title,
.header__image {
  pointer-events: all;
  cursor: pointer;
}

.header__image:hover {
  filter: opacity(0.8);
}

.header__title {
  height: $header-height;
  line-height: $header-height;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0px;
  &.no-click{
    pointer-events: none;
  }
}

.header__image {
  margin: 0 auto;
  height: 32px;
  margin-top: 15px;
}

.header__action {
  //margin-bottom: 6px;
}

.header__action--left {
  .btn:not(.btn--tertiary) {
    margin-left: $tt-spacing-unit-small;
  }
}

.header__action--right {
  margin-left: auto;

  .btn:not(.btn--tertiary) {
    margin-right: $tt-spacing-unit-small;
  }

  .ladda-button{
    transition: none !important;
  }

}

