@import "../../../styles/abstracts/variables";
.checkout__shipping-container{
  margin: 10px, auto;
  .checkout__shipping-country-select{
    display: flex;
    justify-content: center;
    width: 160px;
    border: 1px solid #4b5466;
    margin: 0 auto;
    border-radius: 2px;
    .checkout__shipping-country-option{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 80px;
      &:last-child {
        border-left: 1px solid #4b5466;
      }

      &.active{
        background: #4b5466;
      }
    }

    .checkout__shipping-country-option-flag{
      width: 25px;
      border: 2px solid white;
    }
  }
  .checkout__shipping-country-select-container{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(51, 51, 51, 0.7);
    margin-bottom: 20px;
  }

}

.red-text{
  color:$tt-color-primary;
  font-size: 12px;
  margin-top: 10px;
}