@import "../abstracts/mixins/mq";

* {
  margin: 0;
  padding: 0;
  text-rendering: geometricprecision !important;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
html {
  height: 100%;
  overflow: hidden;
}

img {
  max-width: 100%;
}

// @supports(padding: max(0px)) {
//   body, html{
//     padding-left: unquote('max(#{12px}, env(safe-area-inset-left))');
//     padding-right: unquote('max(#{12px}, env(safe-area-inset-right))');
//   }
// }

.app {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.app--sidebar-open {
  .sidebar {
    transform: translateX(0);
  }

  .app__main,
  & > .header {
    transform: translateX(250px);
  }
}

.app__main,
.app > .header {
  transition: transform 0.2s;
}

.app-staging-banner {
  position: fixed;
  top: 0px;
  height: 20px;
  width: 100%;
  background: rgba(0, 150, 136, 0.2);
  z-index: 9999;
  text-align: center;
  font-size: 10px;
  padding-top: 3px;
  font-weight: bold;
}

#root,
.app__main {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}

// @include mq($from: desktop) {
//   .app {
//     flex-direction: row;
//     justify-content: center;
//
//     .sidebar,
//     .app__main {
//       margin-top: 50px;
//       height: calc(100% - 50px);
//     }
//
//     .app__main {
//       max-width: $tt-max-app-width - $tt-sidebar-width;
//     }
//
//     & > .header {
//       position: fixed;
//       width: 100%;
//       z-index: 1;
//     }
//   }
// }

@include mq($from: desktop) {
  .app {
    //flex-direction: row;
    //justify-content: center;

    .app__main {
      margin-top: 50px;
      height: calc(100% - 50px);
    }

    .app__main {
      max-width: $tt-max-app-width - $tt-sidebar-width;
      margin: 0 auto;
    }
    & > .header {
      //position: fixed;
      width: 100%;
      z-index: 1;
      .header__content {
        max-width: $tt-max-app-width - $tt-sidebar-width;
      }
    }
  }
}

.hidden {
  display: none !important;
}
