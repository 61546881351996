@import "../../styles/abstracts/variables";
.frame-layout-picker{
  border-top: thin solid #efefef;
  padding-top: 10px;
  background: #fdfdfd;
  min-height: 170px;
}

.frame-layout-picker__list{
  display: flex;
  list-style:none;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  margin-left: 5px;
}

.frame-layout-picker__list-item{
  padding: 10px;
  border: 1px solid #efefef;
  border-radius: 4px;
  box-shadow: 3px 3px 3px 0px #dedede;
  margin: 10px;
  background:white;
  font-size: 12px;
  cursor: pointer;
  text-align:center;
  &:hover{
    box-shadow: 3px 3px 3px 0px #ff5a5f4a;
  }

  &.active{
    border: 1px solid #c3c3c3;
  }
  p {
    margin-top:10px;
  }
}