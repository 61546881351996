@import "../../styles/abstracts/variables";

.convert-account{
  max-width: 350px;
  margin: 0px auto;

  

  .form .form-control[disabled]{
    background: #f1f1f1;
  }


  .form .error-detail{
    margin-left: 0px;
    margin-top: 5px;
    height: 50px;
  }
}

.form .error-detail{
  margin-left: 0px;
  margin-top: 5px;
  height: 50px;
}