
@import "../../styles/abstracts/variables";

// html{
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
// }

.editor-modal__cropper{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.cropper-footer-content{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.cropper-canvas, .cropper-drag-box, .cropper-crop-box, .cropper-view-box {
  //will-change: transform;
}

.cropper-modal{
  background-color: rgba(255,255,255,0.8);
}

.cropper-point {
  border-radius: 50%;
  opacity: 1;
  background-color: $tt-color-dark-blue;
  transform: scale(2.5);

  &.point-se {
    width: 10px;
    height: 10px;
    background-image: url("/app/images/text-resize.png");
    background-size: cover;
  }
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }
}

.cropper-line {
  background-color: $tt-color-dark-blue;
}

.cropper-view-box {
  outline: 2px solid $tt-color-dark-blue;
  outline-color: $tt-color-dark-blue; //rgba(73, 189, 161, 0.52);
}

.cropper-center:after,
.cropper-center:before {
  transform: scale(2);
}

.cropper-dashed {
  opacity: 1;
  border: 0 solid $tt-color-dark-blue;

  &.dashed-h {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  &.dashed-v {
    border-right-width: 1px;
    border-left-width: 1px;
  }
}

.cropper-change-photo-btn{
  position: absolute;
  left: 10px;
  bottom: 16px;
  .btn{
    padding: 0 8px;
  }
  .btn__label{
    font-size: 12px;
  }
}

.cropper-save-btn{
  position: absolute;
  right: 10px;
  bottom: 16px;
  .btn{
    padding: 0 8px;
  }
  .btn__label{
    font-size: 12px;
  }
}

.cropper-buttons-container{
  //text-align: center;
  //box-shadow: 0px -1px 1px 0px #ececec;
  //padding-top: 6px;
  //margin-bottom: 3px;
  // .btn__icon{
  //   .icon{
  //     width: 60px;
  //     height: 50px;
  //     top:0px;
  //     svg{
  //       height: 60px;
  //       width: 60px;
  //     }
  //   }
  // }
}

.cropper-bleed-band{
  background: #4242428f;
}

.cropper-bleed-band--text{

  color: white;
  text-align: center;
  font-size: 75%;
  font-weight: 600;

  letter-spacing: 3px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.cropper-bleed-band--text-vertical{
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .cropper-bleed-band--text{
    //display:none;
    font-size: 75%;
  }
}


.ant-notification-notice-message{
  font-size: 14px !important;
}

.cropper-crop-box__bleed, .cropper-crop-box__bleed-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  pointer-events: none;
  top: 0;
  width: 100%;
  overflow: hidden;
}
.cropper-crop-box__bleed{
  box-shadow: 0 1px 0 1000000px rgba(0,0,0,.5);
  z-index: 1;
}


.cropper-wrapper{
  height: 100%;
  max-width: 100%;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}

.editor-modal__cropper{
  margin-top: -8px;
}

.cropper-footer-content{
  overflow: hidden;
  border-top: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  .btn{
    font-size: 12px;
  }
  .cropper-footer-content--actions{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: whitesmoke;
    border-top: 1px solid #ccc;
    .cropper-footer-content--replace-img{

    }

    .cropper-footer-content--save-btn{
      .btn{
        width: 81px;
        text-align: center;
      }
    }
  }


  .cropper-footer-content--rotation-btns{
    .cropper-buttons-container{
      display: flex;
      justify-content: space-around;
      padding: 5px 0px;

      &.center{
        justify-content: center !important;
      }
      //mobile
      //justify-content: space-between;
      //padding: 5px 10px;

      //.btn__icon{
      //   .icon{
      //     width: 60px;
      //     height: 50px;
      //     top:0px;
      //     svg{
      //       height: 60px;
      //       width: 60px;
      //     }
      //   }
      // }

      .cropper-buttons-group-container{
        font-size: 12px;
        text-align: left;
        .cropper-buttons-group-info{
          margin-bottom: 5px;
        }
        .cropper-buttons-group{
          display:flex;
          .btn{
            .icon{
              position: initial;
            }
            //width: 14vw;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .cropper-buttons-container{
      justify-content: space-between !important;
      padding: 5px 10px !important;
    }
  }

}
